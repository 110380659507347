import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function forbiddenCharactersValidator(): ValidatorFn {
  const forbiddenChars = /[<>;\\(){}/=*|$]/;
  return (control: AbstractControl<string>): ValidationErrors | null => {
    const forbidden = forbiddenChars.test(control.value);
    return forbidden ? { forbiddenCharacters: { value: control.value } } : null;
  };
}

export const emailValidationPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
